#tea-cup {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
  overflow: hidden;
}

#tea-cup .smoke {
  position: absolute;
  background: url("smoke-texture-3.png") no-repeat;
  background-size: 0% 0%;
  margin-left: 0px;
}

#tea-cup-smoke image {
  opacity: 0;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.caption {
  cursor: pointer;
  fill: #54af3a;
  position: absolute;
  left: 0;
  top: 0;
}

.caption .mask {
  fill: none;
  stroke: #fff;
  stroke-width: 35px;
}

@keyframes strokeOffset {
  to {
    stroke-dashoffset: 0;
  }
}

.button {
  padding: 8px 16px;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 1px;
  cursor: pointer;
}

/*
#mask-tea {
  animation: strokeOffset 1s linear forwards 1s;
}

#mask-is {
  animation: strokeOffset 1s linear forwards 1s;
}
*/

.numeros{
  font-size:3em;
      -webkit-text-fill-color: rgba(0, 0,0,0);
      -webkit-text-stroke-width: 0.8px;
      -webkit-text-stroke-color: #060606;
  }
  .falling-leaves {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 98%;
      max-height: 100vh;
      overflow: hidden;
  }
  .leaf-scene {
      position: absolute;
      top: 0;
      left: 50%;
      bottom: 0;
      width: 50%;
      transform: translateX(-50%);
  }
      .leaf-scene div {
        position: absolute;
        top: 0;
        left: 0;
        width: 30px;
        height: 60px;
        background-image: url("./leaf.svg");
        background-size: 100%;
        transform-style: preserve-3d;
        backface-visibility: visible;
        border: '1px solid yellow';
  }
  .box-breadcrumb,
  .header-post .info a,
  .entry a{
  position: relative;
  z-index: 2;
  }
  /*
  @media screen and (max-width: 700px) {
    .falling-leaves {
      display: none;
    }
  }
  */
