.ant-menu-vertical {
  border-right: none;
}

.ant-menu-horizontal,
.ant-menu-submenu-horizontal {
  user-select: none;
}

/*
.ant-menu-submenu-popup > .ant-menu {
  background-color: #ffffff;
  background-image: url('./products.jpg');
  background-position: 0,0;
  background-repeat: no-repeat;
  background-size: 30%;
}
*/
