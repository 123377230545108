@import url(https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap);
@media (max-width: 575px) {
  html, body {
    overflow-x: hidden;
  }
}

body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  */
  font-family: Barlow Condensed, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#tea-cup {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
  overflow: hidden;
}

#tea-cup .smoke {
  position: absolute;
  background: url(/static/media/smoke-texture-3.4976b2ac.png) no-repeat;
  background-size: 0% 0%;
  margin-left: 0px;
}

#tea-cup-smoke image {
  opacity: 0;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.caption {
  cursor: pointer;
  fill: #54af3a;
  position: absolute;
  left: 0;
  top: 0;
}

.caption .mask {
  fill: none;
  stroke: #fff;
  stroke-width: 35px;
}

@-webkit-keyframes strokeOffset {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes strokeOffset {
  to {
    stroke-dashoffset: 0;
  }
}

.button {
  padding: 8px 16px;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 1px;
  cursor: pointer;
}

/*
#mask-tea {
  animation: strokeOffset 1s linear forwards 1s;
}

#mask-is {
  animation: strokeOffset 1s linear forwards 1s;
}
*/

.numeros{
  font-size:3em;
      -webkit-text-fill-color: rgba(0, 0,0,0);
      -webkit-text-stroke-width: 0.8px;
      -webkit-text-stroke-color: #060606;
  }
  .falling-leaves {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 98%;
      max-height: 100vh;
      overflow: hidden;
  }
  .leaf-scene {
      position: absolute;
      top: 0;
      left: 50%;
      bottom: 0;
      width: 50%;
      transform: translateX(-50%);
  }
      .leaf-scene div {
        position: absolute;
        top: 0;
        left: 0;
        width: 30px;
        height: 60px;
        background-image: url(/static/media/leaf.c3aa6b4d.svg);
        background-size: 100%;
        transform-style: preserve-3d;
        -webkit-backface-visibility: visible;
                backface-visibility: visible;
        border: '1px solid yellow';
  }
  .box-breadcrumb,
  .header-post .info a,
  .entry a{
  position: relative;
  z-index: 2;
  }
  /*
  @media screen and (max-width: 700px) {
    .falling-leaves {
      display: none;
    }
  }
  */

.ant-menu-vertical {
  border-right: none;
}

.ant-menu-horizontal,
.ant-menu-submenu-horizontal {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

/*
.ant-menu-submenu-popup > .ant-menu {
  background-color: #ffffff;
  background-image: url('./products.jpg');
  background-position: 0,0;
  background-repeat: no-repeat;
  background-size: 30%;
}
*/

.ant-layout-footer {
  padding: inherit;
}

Footer ul {
  text-align: left;
}

Footer li {
  list-style: none;
}

/*
Footer li:before {
  content: '> ';
}
*/
