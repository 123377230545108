.ant-layout-footer {
  padding: inherit;
}

Footer ul {
  text-align: left;
}

Footer li {
  list-style: none;
}

/*
Footer li:before {
  content: '> ';
}
*/